import React, { useContext, useEffect, useState } from "react";
import "./Styles.css";
import MyContext from "../../../context/MyContext";
import { Card, Input, message, Modal, Button } from "antd";
import Api, { USER_API_URL } from "../../../Api/Api";
import axios from "axios";
import StripeForm from "./Card/Card";
import { useHistory } from "react-router-dom";

export default function Homecleaning() {
  axios.defaults.withCredentials = true;
  const [cleaner, setCleaner] = useState(0);
  const [count, setCount] = useState(0);
  const [time, setTime] = useState(0);
  const [oft, setOft] = useState(0);
  const [date, setDate] = useState(0);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const [searchId, setSearchId] = useState("");

  const { state, updateState } = useContext(MyContext);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDayClick = (dayName) => {
    if (!selectedDays.includes(dayName)) {
      setSelectedDays([...selectedDays, dayName]);
    } else {
      const updatedDays = selectedDays.filter((day) => day !== dayName);
      setSelectedDays(updatedDays);
    }
  };

  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleContactChange = (e) => setContact(e.target.value);
  const handleSearchIdChange = (e) => setSearchId(e.target.value);

  const showModal = () => setVisible(true);
  const handleOk = () => {
    isEdit ? updateBooking() : createBooking();
    setVisible(false);
  };
  const handleCancel = () => setVisible(false);

  const HourSpent = [
    { name: "Two Hours", value: 2 },
    { name: "Three Hours", value: 3 },
    { name: "Four Hours", value: 4 },
    { name: "Five Hours", value: 5 },
  ];

  const Days = [
    { name: "Monday" },
    { name: "Tuesday" },
    { name: "Wednesday" },
    { name: "Thursday" },
    { name: "Friday" },
    { name: "Saturday" },
  ];

  const Often = [
    { name: "Once", description: "Book a one time cleaning session" },
    {
      name: "Weekly",
      description:
        "Get the same cleaner each time, Re-schedule easily through the app",
    },
    {
      name: "Multiple times a week",
      description:
        "Get the same cleaner each time, Re-schedule easily through the app",
    },
  ];

  const strg = (datexx) => {
    if (!datexx) return "";
    const date = new Date(datexx);
    return date.toLocaleString();
  };

  const calculateTotalAmount = () => 30 * cleaner * count + oft;
  const calculateVAT = () => calculateTotalAmount() * 0.05;
  const calculateNetCost = () => calculateTotalAmount() + calculateVAT();

  const createBooking = async () => {
    const values = {
      hours: time,
      cleaners: cleaner,
      Material: oft,
      Frequency:
        time === 1 ? "Once" : time === 2 ? "Weekly" : "Multiple times a week",
      days: selectedDays,
      date: new Date(date),
      TotalAmount: calculateTotalAmount(),
      Vat: calculateVAT(),
      Pay: calculateNetCost(),
      Email: email,
      Contact: contact,
      Name: name,
    };

    try {
      const response = await axios.post(`${USER_API_URL}/homeBook`, values);
      const { _id } = response.data;
      message.success("Booking successful!");
      history.push(`/success/${_id}`);
    } catch (error) {
      console.error("Booking failed:", error);
      message.error("Booking Failed!");
    }
  };

  const fetchBookingData = async (_id) => {
    try {
      const response = await axios.get(`${USER_API_URL}/homeBook/${_id}`);
      const data = response.data;
      setDate(data.date);
      setName(data.Name);
      setEmail(data.Email);
      setContact(data.Contact);
      setCleaner(data.cleaners);
      setCount(data.hours);
      setOft(data.Material);
      setTime(
        data.Frequency === "Once" ? 1 : data.Frequency === "Weekly" ? 2 : 3
      );
      setSelectedDays(data.days);
      setIsEdit(true);
      setBookingId(_id);
      // showModal();
    } catch (error) {
      console.error("Failed to fetch booking data:", error);
      message.error("Failed to fetch booking data");
    }
  };

  const updateBooking = async () => {
    const values = {
      hours: time,
      cleaners: cleaner,
      Material: oft,
      Frequency:
        time === 1 ? "Once" : time === 2 ? "Weekly" : "Multiple times a week",
      days: selectedDays,
      date: new Date(date),
      TotalAmount: calculateTotalAmount(),
      Vat: calculateVAT(),
      Pay: calculateNetCost(),
      Email: email,
      Contact: contact,
      Name: name,
    };

    try {
      await axios.put(`${USER_API_URL}/homeBook/${bookingId}`, values);
      message.success("Booking updated successfully!");
      setIsEdit(false);
      setBookingId(null);
    } catch (error) {
      console.error("Failed to update booking:", error);
      message.error("Failed to update booking");
    }
  };

  const cancelBooking = async () => {
    try {
      await axios.delete(`${USER_API_URL}/homeBook/${bookingId}`);
      message.success("Booking cancelled successfully!");
      setIsEdit(false);
      setBookingId(null);
      resetForm();
    } catch (error) {
      console.error("Failed to cancel booking:", error);
      message.error("Failed to cancel booking");
    }
  };

  const resetForm = () => {
    setDate(0);
    setName("");
    setEmail("");
    setContact("");
    setCleaner(0);
    setCount(0);
    setOft(0);
    setTime(0);
    setSelectedDays([]);
  };

  return (
    <div className="container">
      <br />
      <Modal
        title={isEdit ? "Update Booking" : "Booking"}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={isEdit ? "Update Booking" : "Request Booking"}
        width={800}
      >
        <div className="row">
          <div className="col">
            <StripeForm />
          </div>
          <div className="col">
            <div>
              <p>Enter your name:</p>
              <Input
                value={name}
                onChange={handleNameChange}
                style={{ height: 40 }}
                placeholder="Your Name"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your email:</p>
              <Input
                value={email}
                onChange={handleEmailChange}
                style={{ height: 40 }}
                placeholder="Email Address"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your phone:</p>
              <Input
                value={contact}
                onChange={handleContactChange}
                style={{ height: 40 }}
                placeholder="Contact Number"
              />
            </div>
          </div>
        </div>
      </Modal>
      <br />
      <div align="center">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            Home Services
            <br />
          </h1>
          <div className="manage-booking">
            {/* <h2>Manage Booking</h2> */}
            <div>
              <Input
                value={searchId}
                onChange={handleSearchIdChange}
                placeholder="Enter Booking ID"
                style={{ width: "300px", marginRight: "10px" }}
              />
              <Button
                onClick={() => fetchBookingData(searchId)}
                type="primary"
                style={{ marginRight: "10px" }}
              >
                Retrieve Booking
              </Button>
              <Button onClick={() => resetForm()} type="default">
                Reset
              </Button>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="col-lg-7 col-md-6 col-sm-12 colxs-12 ">
          <div className="whit">
            <div>
              <div className="often">
                <h3>How many hours?</h3>
                <div>
                  {HourSpent.map((a) => (
                    <label
                      key={a.value}
                      style={
                        a.value === count
                          ? { background: "#df2528", color: "white" }
                          : { background: "none" }
                      }
                      onClick={() => setCount(a.value)}
                    >
                      {a.name}
                    </label>
                  ))}
                </div>
              </div>
              <br />
              <div className="increment">
                <h3>Number Of Cleaners</h3>
                <div className="tabc">
                  <label onClick={() => setCleaner(cleaner - 1)}>-</label>
                  <span>{cleaner}</span>
                  <label onClick={() => setCleaner(cleaner + 1)}>+</label>
                </div>
              </div>
              <br />
              <div className="datesi">
                <h3>How often do you need cleaning?</h3>
                <div>
                  {Often.map((a, b) => (
                    <div
                      key={b}
                      onClick={() => setTime(b + 1)}
                      style={
                        time === b + 1
                          ? {
                              border: "1px solid grey",
                              marginBottom: "10px",
                              padding: "5px",
                              borderRadius: 10,
                              paddingLeft: "20px",
                              background: "#df2528",
                              color: "white",
                            }
                          : {
                              border: "1px solid grey",
                              marginBottom: "10px",
                              padding: "5px",
                              borderRadius: 10,
                              paddingLeft: "20px",
                              background: "none",
                            }
                      }
                    >
                      <label style={{ fontWeight: "bold" }}>{a.name}</label>
                      <p>{a.description}</p>
                    </div>
                  ))}
                </div>
              </div>
              <br />
              <div
                style={time === 3 ? { display: "block" } : { display: "none" }}
              >
                <h3>Which days of the week do you want the service?</h3>
                <div className="often">
                  {Days.map((day) => (
                    <label
                      key={day.name}
                      style={{
                        background: selectedDays.includes(day.name)
                          ? "#df2528"
                          : "none",
                      }}
                      onClick={() => handleDayClick(day.name)}
                    >
                      {day.name}
                    </label>
                  ))}
                </div>
              </div>
              <br />
              <div>
                <h3>Extra Cleaning Materials</h3>
                <div className="dappbox">
                  <div className="often" style={{ marginRight: 20 }}>
                    <label
                      onClick={() => setOft(10)}
                      style={
                        oft === 10
                          ? { background: "#df2528", color: "white" }
                          : { background: "none" }
                      }
                    >
                      Yes
                    </label>
                    <label
                      onClick={() => setOft(0)}
                      style={
                        oft === 0
                          ? { background: "#df2528" }
                          : { background: "none" }
                      }
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <br />
              <div className="datesi">
                <h3>Pick your start date and time?</h3>
                <div>
                  <input
                    style={{ borderRadius: 10 }}
                    type="datetime-local"
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col" style={{ padding: 10 }}>
          <div className="whit" style={{ background: "white", margin: "5px" }}>
            <br />
            <h2>Booking Summary</h2>
            <hr />
            <div>
              <div className="row">
                <div className="col" style={{ fontWeight: "bold" }}>
                  No. of hours
                </div>
                <div className="col">{count}</div>
              </div>
              <div className="row">
                <div className="col" style={{ fontWeight: "bold" }}>
                  No Of Cleaners
                </div>
                <div className="col">{cleaner}</div>
              </div>
              <div className="row">
                <div className="col" style={{ fontWeight: "bold" }}>
                  Materials
                </div>
                <div className="col">{oft} AED</div>
              </div>
              <div className="row">
                <div className="col" style={{ fontWeight: "bold" }}>
                  Frequency
                </div>
                <div className="col">
                  {time === 1
                    ? "Once "
                    : time === 2
                    ? "Weekly "
                    : "Multiple times a week"}
                </div>
              </div>
              <div className="row">
                <div className="col" style={{ fontWeight: "bold" }}>
                  Days
                </div>
                <div className="col">
                  {selectedDays.map((a) => (
                    <label key={a} style={{ marginRight: 10 }}>
                      {a}{" "}
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">Date and Time:</div>
              <div className="col">{strg(date)}</div>
            </div>
            <hr />
            <div className="row">
              <div className="col">Total Amount:</div>
              <div className="col">{calculateTotalAmount()} AED</div>
            </div>
            <div className="row">
              <div className="col">VAT (5%):</div>
              <div className="col">{calculateVAT()} AED</div>
            </div>
            <div className="row">
              <div className="col" style={{ fontWeight: "bold" }}>
                Net to pay:
              </div>
              <div className="col" style={{ fontSize: "23px" }}>
                {calculateNetCost()} AED
              </div>
            </div>
          </div>
          <br />
          <button
            className="btn btn-success bknow"
            style={{ padding: "10px" }}
            onClick={() => showModal()}
          >
            {isEdit ? "Update Booking" : "BOOK NOW"}
          </button>
          {isEdit && (
            <Button
              type="danger"
              style={{ marginTop: "10px" }}
              onClick={cancelBooking}
            >
              Cancel Booking
            </Button>
          )}
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
