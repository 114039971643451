import React from "react";
import "./About.css";

export default function ContactUs() {
  return (
    <div id="about">
      <div className="about">
        <div className="container" align="center">
          <div className="heading" align="center">
            <h1 data-aos="fade-down">
              CONTACT <span>US</span>
            </h1>
          </div>
          <div className="aboutmain">
            <p data-aos="fade-down" style={{ color: "#2d438e" }}>
              Smart Box General Maintenance & Cleaning Services LLC is a growing
              commercial cleaning and general maintenance provider located in
              Abu Dhabi, United Arab Emirates. <br />
              <br /> We provide a range of cleaning services for homes;
              commercial and industrial clients and we also work along with real
              agents in order to arrange end of lease clean ups for residential
              properties. <br />
              <br /> Smart Box General Maintenance & Cleaning Services LLC
              brings a fresh and professional approach to general maintenance
              and cleaning services; our goal is to exceed the expectations of
              every client by offering outstanding customer service, exceptional
              quality, and add greater value for the service.
            </p>
          </div>
          <div className="aboutmain">
          <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14523.149101998735!2d54.3742112!3d24.4928264!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e67072212f301%3A0x9933a766f3fd1a82!2sSMART%20BOX%20BROKERS%20SERVICES%20LLC!5e0!3m2!1sen!2sae!4v1682887713401!5m2!1sen!2sae"
                    height="500"
                    width="95%"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
