import React, { useState, useEffect } from 'react';
import { useParams, Link, useRouteMatch } from 'react-router-dom';
import './BlogPost.css';

export default function BlogPost() {
  const { id, urls } = useParams();
  const [post, setPost] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { url } = useRouteMatch();

  useEffect(() => {
    fetch('/blogPosts.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const foundPost = data.find(post => post.urls === urls);
        setPost(foundPost);
        setLoading(false);

        const uniqueCategories = [...new Set(data.map(post => post.category))];
        setCategories(uniqueCategories);
      })
      .catch(error => {
        console.error('Error fetching the blog post:', error);
        setLoading(false);
      });
  }, [id, urls]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!post) {
    return (
      <div className="blog-post-full">
        <center>
          <h1>Post</h1>
          <p>Post Not Found!</p>
          <Link to={`${url.split('/').slice(0, -1).join('/')}`} className="back-to-blog">Back to Blog</Link>
        </center>
      </div>
    );
  }

  return (


    <div className="blog-post-full">
    <h1>{post.title}</h1>
    <img src={post.image} alt={post.title} />
    <p>{post.content}</p>
    <Link to={`${url.split('/').slice(0, -1).join('/')}`} className="back-to-blog">Back to Blog</Link>
  </div>


   
     
   
     
  );
}
