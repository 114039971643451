import React from "react";
import "./Success.css";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function Success() {
  const { id } = useParams();
  return (
    <div>
      <div className="ucess">
        <div className="divx">
          <div>
            <h3>
              Booking <br />
              Confirmed Successfully
            </h3>
            <p>
              <p>
                Your booking has been successfully confirmed!
                <br /> Thank you for choosing SmartBox Cleaning services. <br />
                We look forward to welcoming you. For any inquiries, <br />{" "}
                please contact us at admin@smartboxcleaningservices.com
              </p>
            </p>
            <br />
            <br />
            {/* <button className="btn btn-success xx">VIEW BOOKING </button> */}
            <Link to="/">
              <button className="btn btn-danger">BACK TO BOOKINGS</button>
            </Link>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
