import React, { useEffect, useState } from "react";
import { Input, message, Modal, Button } from "antd";
import axios from "axios";
import { USER_API_URL } from "../../../Api/Api";
import StripeForm from "./Card/Card";
import useFetch from "../../../Hook/useFetch";
import { useHistory } from "react-router-dom";

export default function Shoe() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();
  axios.defaults.withCredentials = true;
  // State for cloth sizes
  const [clothSize, setclothSize] = useState([]);
  const { data: DryCleaning = [] } = useFetch(`${USER_API_URL}/Shoe`);

  useEffect(() => {
    if (DryCleaning.length > 0) {
      const formattedClothSize = DryCleaning.map((item) => ({
        size: item.Name,
        selected: false,
        price: item.Price,
      }));
      setclothSize(formattedClothSize);
    }
  }, [DryCleaning]);

  // State for booking details
  const [startDate, setStartDate] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [visible, setVisible] = useState(false);

  // State for managing booking
  const [bookingId, setBookingId] = useState("");
  const [bookingDetails, setBookingDetails] = useState(null);

  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleContactChange = (e) => setContact(e.target.value);
  const handleStartDateChange = (e) => setStartDate(e.target.value);

  const showModal = () => setVisible(true);
  const handleOk = () => {
    onBooking();
    setVisible(false);
  };
  const handleCancel = () => setVisible(false);

  const handleSizeClick = (index) => {
    const updatedSizes = clothSize.map((carpet, i) => ({
      ...carpet,
      selected: i === index ? !carpet.selected : carpet.selected,
    }));
    setclothSize(updatedSizes);
  };

  const calculateTotalAmount = () => {
    return clothSize.reduce(
      (total, item) => (item.selected ? total + item.price : total),
      0
    );
  };

  const calculateVAT = () => {
    const totalAmount = calculateTotalAmount();
    return (totalAmount * 0.05).toFixed(2); // VAT is 5%
  };

  const calculateNetCost = () => {
    const totalAmount = calculateTotalAmount();
    const vat = calculateVAT();
    return (totalAmount + parseFloat(vat)).toFixed(2);
  };

  const onBooking = async () => {
    const bookingDetails = {
      Name: name,
      Email: email,
      Contact: contact,
      DateTime: startDate,
      booking: clothSize.filter((item) => item.selected),
      totalPrice: calculateTotalAmount(),
      vatAmount: calculateVAT(),
      totalAmount: calculateNetCost(),
    };

    try {
      const response = await axios.post(`${USER_API_URL}/Shoe`, bookingDetails);
      const { _id } = response.data;
      message.success("Booking successful!");
      history.push(`/success/${_id}`);
    } catch (error) {
      message.error("Booking Failed!");
    }
  };

  const handleBookingIdChange = (e) => setBookingId(e.target.value);

  const retrieveBooking = async () => {
    try {
      const response = await axios.get(`${USER_API_URL}/Shoe/${bookingId}`);
      const data = response.data;
      // console.log("data", data);
      setBookingDetails(data);
      setName(data.Name);
      setEmail(data.Email);
      setContact(data.Contact);
      setStartDate(data.DateTime);
      // setStartDate(data.DateTime);
      // setStartDate(data.DateTime);
      const updatedClothSize = clothSize.map((item) => ({
        ...item,
        selected: data.booking.some((booking) => booking.size === item.size),
      }));
      setclothSize(updatedClothSize);
      message.success("Booking retrieved successfully!");
    } catch (error) {
      message.error("Failed to retrieve booking!");
    }
  };

  const updateBooking = async () => {
    const updatedDetails = {
      Name: name,
      Email: email,
      Contact: contact,
      startDate,
      booking: clothSize.filter((item) => item.selected),
      totalPrice: calculateTotalAmount(),
      vatAmount: calculateVAT(),
      totalAmount: calculateNetCost(),
    };

    try {
      await axios.put(`${USER_API_URL}/Shoe/${bookingId}`, updatedDetails);
      message.success("Booking updated successfully!");
    } catch (error) {
      message.error("Failed to update booking!");
    }
  };

  return (
    <div className="container">
      <br />
      <Modal
        title="Booking"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Request Booking"}
        width={800}
      >
        <div className="row">
          <div className="col">
            <StripeForm />
          </div>
          <div className="col">
            <div>
              <p>Enter your name:</p>
              <Input
                value={name}
                onChange={handleNameChange}
                style={{ height: 40 }}
                placeholder="Your Name"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your email:</p>
              <Input
                value={email}
                onChange={handleEmailChange}
                style={{ height: 40 }}
                placeholder="Email Address"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your phone:</p>
              <Input
                value={contact}
                onChange={handleContactChange}
                style={{ height: 40 }}
                placeholder="Contact Number"
              />
            </div>
          </div>
        </div>
      </Modal>
      <br />
      <div align="center">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            Shoe Cleaning
            <br />
          </h1>

          <div>
            {/* <h3>Manage Booking</h3> */}
            <Input
              value={bookingId}
              onChange={handleBookingIdChange}
              placeholder="Enter Booking ID"
              style={{ width: "300px", marginRight: "10px" }}
            />
            <Button type="primary" onClick={retrieveBooking}>
              Retrieve Booking
            </Button>
            <Button
              type="primary"
              onClick={updateBooking}
              style={{ marginLeft: "10px" }}
            >
              Update Booking
            </Button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-lg-7 col-md-6 col-sm-12 colxs-12">
          <div className="whit">
            <div>
              <div className="often">
                <h3>Select Size?</h3>
                <div>
                  {clothSize.map((carpet, index) => (
                    <label
                      key={index}
                      onClick={() => handleSizeClick(index)}
                      style={
                        carpet.selected
                          ? {
                              background: "#df2528",
                              width: "45%",
                              color: "white",
                            }
                          : { background: "none", width: "45%" }
                      }
                    >
                      {carpet.size}
                    </label>
                  ))}
                </div>
              </div>
              <br />
              <div className="datesi">
                <h3>Pick your start date and time?</h3>
                <div>
                  <input
                    type="datetime-local"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col" style={{ padding: 10 }}>
          <div className="whit" style={{ background: "white", margin: "5px" }}>
            <br />
            <h2>Booking Summary</h2>
            <hr />
            {clothSize.map((carpet, index) => {
              if (carpet.selected) {
                return (
                  <div className="row" key={index}>
                    <div className="col">{carpet.size}</div>
                    <div className="col">{carpet.price} AED</div>
                  </div>
                );
              }
              return null;
            })}
            <br />
            <div className="row">
              <div className="col">Total Amount:</div>
              <div className="col">{calculateTotalAmount()} AED</div>
            </div>
            <div className="row">
              <div className="col">VAT (5%):</div>
              <div className="col">{calculateVAT()} AED</div>
            </div>
            <div className="row">
              <div className="col">Net to pay:</div>
              <div className="col">{calculateNetCost()} AED</div>
            </div>
          </div>
          <button className="btn btn-success bknow" onClick={showModal}>
            BOOK NOW
          </button>
          <br />
          <br />
          <div>
            {/* {bookingDetails && (
              <div style={{ marginTop: "20px" }}>
                <h3>Booking Details</h3>
                <pre>{JSON.stringify(bookingDetails, null, 2)}</pre>
              </div>
            )} */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
