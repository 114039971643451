import React, { useState } from "react";
import "./Quote.css";
import Api from "../../../Api/Api";

export default function AboutUs() {
  const outclient = [
    "Pharmaceuticals",
    "School and colleges",
    "Real estate and developers",
    "Hotel and restaurants",
    "cooperate sectors",
    "Retail and Malls",
    "Industries and Factories",
  ];
  return (
    <div>
      <div className="container">
        <div className=" ">
          <br />
          <br />
          <br />
          <br />

          <div className="heading" align="center">
            <h1 data-aos="fade-down">
              ABOUT <span>SMARTBOX</span>
              <br />
              {/* <span> Smartboxcleaning Services </span> */}
            </h1>
          </div>

          <p align="center">
            Smart Box General Maintenance & Cleaning Services LLC is a growing
            commercial cleaning and general maintenance provider located in Abu
            Dhabi, United Arab Emirates. We provide a range of cleaning services
            for homes; commercial and industrial clients and we also work along
            with real Estate agents in order to arrange end of lease clean ups
            for residential properties.
          </p>
          <br />
          <p align="center">
            Smart Box General Maintenance & Cleaning Services LLC brings a fresh
            and professional approach to general maintenance and cleaning
            services; our goal is to exceed the expectations of every client by
            offering outstanding customer service, exceptional quality, and add
            greater value for the service.
          </p>
          <br />
          <br />
          <div className="heading" align="center">
            <h1 data-aos="fade-down">
              VISION <br />
              {/* <span> Smartboxcleaninfg Services </span> */}
            </h1>
          </div>
          <p align="center">
            Our vision is to fully understand our clients’ needs and exceed
            their expectations throughout the project life cycle of general
            maintenance and cleaning services
          </p>
          <br />

          <br />
          <div className="heading" align="center">
            <h1 data-aos="fade-down">
              MISSION STATEMENT
              <br />
              {/* <span> Smartboxcleaninfg Services </span> */}
            </h1>
          </div>
          <p align="center">
            To provide comprehensive, high-quality services to our clients with
            the highest level of integrity and professionalism and to create
            clean, peaceful work environments for them.
          </p>
          <br />

          <div align="center">
            <div className="heading" align="center">
              <h1 data-aos="fade-down">
                WHY CHOOSE SMART BOX
                <br />
                {/* <span> Smartboxcleaninfg Services </span> */}
              </h1>
            </div>

            <div>
              <b>Customer Focused</b> <br />
              We are customer focused by giving our clients excellent service
              levels that meet their expectations which is utmost important to
              us.
            </div>
            <br />
            <div>
              <b>Training</b> <br />
              As part of our employee training program, we provide entry level
              training for staff as well as on the job skill enhancements.
            </div>
            <br />
            <div>
              <b>Professionalism</b> <br />
              Throughout our business relationships, we strive to maintain high
              ethical standards, fairness, and honesty.
            </div>
            <br />
            <div>
              <b>Reliability</b> <br />
              In order to ensure continuous improvement in both our customer
              service and product offering, we adhere to ISO 9000's quality
              standards.
            </div>
            <br />
            <div>
              <b>Professionalism</b> <br />
              Throughout our business relationships, we strive to maintain high
              ethical standards, fairness, and honesty.
            </div>
            <br />
            <div>
              <b>100% Satisfaction Guaranteed!</b> <br />
              Please let us know if there are any errors in our work. We will do
              whatever it takes to fix them. .
            </div>
          </div>
          <br />
          <br />

          <br />
          <br />
          <br />
          <div>
            <div className="" align="center"></div>
            <div className="heading" align="center">
              <h1 data-aos="fade-down">
                <span>OUR SERVICES</span>
                <br />
                {/* <span> Smartboxcleaninfg Services </span> */}
              </h1>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <ul>
                  <li> Home cleaning and Office cleaning</li>
                  <li> Nanny and Maid services</li>
                  <li> AC Duct Cleaning & Disinfection & AC Maintenance</li>
                  <li> Kitchen Extract Duct Cleaning and De-Greasing</li>
                  <li> Water Tank Cleaning & Disinfection Service</li>
                </ul>
              </div>
              <div className="col" style={{ borderLeft: "1px solid grey" }}>
                <ul>
                  <li> Water & Air Testing.</li>
                  <li> Air Treatment.</li>
                  <li> Grease Trap Cleaning & Installation.</li>
                  <li> Civil & MEP Maintenance</li>
                  <li> Post construction cleaning</li>
                </ul>
              </div>
            </div>
          </div>

          <br />
          <br />
          <br />

          <div>
            <div className="heading" align="center">
              <h1 data-aos="fade-down">
                <>OUR CLIENTS</>
                <br />
                {/* <span> Smartboxcleaninfg Services </span> */}
              </h1>
            </div>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              {outclient.map((a, b) => (
                <label
                  style={{
                    background: "#2d438e",
                    padding: "10px",
                    color:"#fff",
                    
                    borderRadius: "5px",
                    margin: 10,
                  }}
                >
                  {a}
                </label>
              ))}
            </div>
          </div>

          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}
