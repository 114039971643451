import React, { useState, useEffect } from "react";
import { Input, Modal, message, Button } from "antd";
import axios from "axios";
import StripeForm from "./Card/Card";
import { USER_API_URL } from "../../../Api/Api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function CarpetCleaning() {
  axios.defaults.withCredentials = true;
  const history = useHistory();

  const [carpetSizes, setCarpetSizes] = useState([
    { size: "Small Size", selected: false, price: 100 },
    { size: "Medium Size (Up to 8 M)", selected: false, price: 120 },
    { size: "Large Carpet (Up to 12 M)", selected: false, price: 200 },
    { size: "X-large (Up to 12 M)", selected: false, price: 250 },
  ]);

  const [carpetQuantities, setCarpetQuantities] = useState([
    { quantity: "1 Piece", selected: false, count: 1 },
    { quantity: "2 Pieces", selected: false, count: 2 },
    { quantity: "3 Pieces", selected: false, count: 3 },
    { quantity: "4 Pieces", selected: false, count: 4 },
  ]);

  const [startDate, setStartDate] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [visible, setVisible] = useState(false);
  const [Editing, setEditing] = useState(false);

  // State for managing bookings
  const [bookingId, setBookingId] = useState("");
  const [bookingDetails, setBookingDetails] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleContactChange = (e) => setContact(e.target.value);
  const handleBookingIdChange = (e) => setBookingId(e.target.value);

  const showModal = () => setVisible(true);
  const handleOk = () => {
    if (name && email && contact && startDate) {
      !Editing ? handleBooking() : updateBooking();
      setVisible(false);
    } else {
      message.warning("Please fill out all required fields.");
    }
  };
  const handleCancel = () => setVisible(false);

  const handleBooking = async () => {
    const selectedSize = carpetSizes.find((carpet) => carpet.selected);
    const selectedQuantity = carpetQuantities.find(
      (quantity) => quantity.selected
    );
    if (!selectedSize || !selectedQuantity || !startDate) {
      alert("Please select carpet size, quantity, and start date.");
      return;
    }
    const bookingData = {
      size: selectedSize.size,
      price: selectedSize.price,
      quantity: selectedQuantity.quantity,
      count: selectedQuantity.count,
      startDate: new Date(startDate),
      totalAmount: calculateTotalAmount(),
      vat: calculateVAT(),
      netCost: calculateNetCost(),
      Email: email,
      Contact: contact,
      Name: name,
      DateTime: startDate,
    };
    try {
      const response = await axios.post(
        `${USER_API_URL}/carpetbooking`,
        bookingData
      );
      // message.success("Booking successful!");
      const { _id } = response.data;
      message.success("Booked Successfully");
      // message.success("Booking Successful");
      history.push(`/success/${_id}`);
      resetForm();
    } catch (error) {
      message.error("Booking failed. Please try again.");
    }
  };

  const retrieveBooking = async () => {
    try {
      const response = await axios.get(
        `${USER_API_URL}/carpetbooking/${bookingId}`
      );
      const data = response.data;
      setBookingDetails(data);
      setName(data.Name);
      setEmail(data.Email);
      setContact(data.Contact);
      setStartDate(data.startDate);
      const updatedSizes = carpetSizes.map((size) => ({
        ...size,
        selected: size.size === data.size,
      }));
      const updatedQuantities = carpetQuantities.map((quantity) => ({
        ...quantity,
        selected: quantity.count === data.count,
      }));
      setEditing(true);
      setCarpetSizes(updatedSizes);
      setCarpetQuantities(updatedQuantities);
      message.success("Booking retrieved successfully!");
    } catch (error) {
      message.error("Failed to retrieve booking!");
    }
  };

  const updateBooking = async () => {
    const selectedSize = carpetSizes.find((carpet) => carpet.selected);
    const selectedQuantity = carpetQuantities.find(
      (quantity) => quantity.selected
    );
    if (!selectedSize || !selectedQuantity || !startDate) {
      alert("Please select carpet size, quantity, and start date.");
      return;
    }
    const updatedDetails = {
      size: selectedSize.size,
      price: selectedSize.price,
      quantity: selectedQuantity.quantity,
      count: selectedQuantity.count,
      startDate: new Date(startDate),
      totalAmount: calculateTotalAmount(),
      vat: calculateVAT(),
      netCost: calculateNetCost(),
      Email: email,
      Contact: contact,
      Name: name,
    };
    try {
      await axios.put(
        `${USER_API_URL}/carpetbooking/${bookingId}`,
        updatedDetails
      );
      message.success("Booking updated successfully!");
    } catch (error) {
      message.error("Failed to update booking!");
    }
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setContact("");
    setStartDate("");
    setCarpetSizes(carpetSizes.map((size) => ({ ...size, selected: false })));
    setCarpetQuantities(
      carpetQuantities.map((quantity) => ({ ...quantity, selected: false }))
    );
  };

  const handleSizeClick = (index) => {
    const updatedSizes = carpetSizes.map((carpet, i) => ({
      ...carpet,
      selected: i === index ? true : false,
    }));
    setCarpetSizes(updatedSizes);
  };

  const handleQuantityClick = (index) => {
    const updatedQuantities = carpetQuantities.map((quantity, i) => ({
      ...quantity,
      selected: i === index ? true : false,
    }));
    setCarpetQuantities(updatedQuantities);
  };

  const calculateTotalAmount = () => {
    let total = 0;
    carpetSizes.forEach((carpetSize) => {
      carpetQuantities.forEach((quantity) => {
        if (carpetSize.selected && quantity.selected) {
          total += carpetSize.price * quantity.count;
        }
      });
    });
    return total;
  };

  const calculateVAT = () => {
    const totalAmount = calculateTotalAmount();
    return (totalAmount * 0.05).toFixed(2);
  };

  const calculateNetCost = () => {
    const totalAmount = calculateTotalAmount();
    const vat = calculateVAT();
    return (totalAmount + parseFloat(vat)).toFixed(2);
  };

  return (
    <div className="container">
      <br />
      <Modal
        title="Booking"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Request Booking"}
        width={800}
      >
        <div className="row">
          <div className="col">
            <StripeForm />
          </div>
          <div className="col">
            <div>
              <p>Enter your name:</p>
              <Input
                value={name}
                onChange={handleNameChange}
                style={{ height: 40 }}
                placeholder="Your Name"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your email:</p>
              <Input
                value={email}
                onChange={handleEmailChange}
                style={{ height: 40 }}
                placeholder="Email Address"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your phone:</p>
              <Input
                value={contact}
                onChange={handleContactChange}
                style={{ height: 40 }}
                placeholder="Contact Number"
              />
            </div>
          </div>
        </div>
      </Modal>
      <br />
      <div align="center">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">Carpet Cleaning</h1>
          <div>
            {/* <h2>Manage Booking</h2> */}
            <div>
              <Input
                value={bookingId}
                onChange={handleBookingIdChange}
                placeholder="Enter Booking ID"
                style={{ width: "300px", marginRight: "10px" }}
              />
              <Button
                onClick={retrieveBooking}
                type="primary"
                style={{ marginRight: "10px" }}
              >
                Retrieve Booking
              </Button>
              <Button
                onClick={() => {
                  window.location.reload();
                }}
                type="default"
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-lg-7 col-md-6 col-sm-12 colxs-12">
          <div className="whit">
            <div>
              <div className="often">
                <h3>What size carpet do you want to clean?</h3>
                <div>
                  {carpetSizes.map((carpet, index) => (
                    <label
                      key={index}
                      onClick={() => handleSizeClick(index)}
                      style={
                        carpet.selected
                          ? { background: "lightgrey", width: "32%" }
                          : { background: "none", width: "32%" }
                      }
                    >
                      {carpet.size}
                    </label>
                  ))}
                </div>
              </div>
              <br />
              <div className="often">
                <h3>How many carpets do you want to clean?</h3>
                <div>
                  {carpetQuantities.map((quantity, index) => (
                    <label
                      key={index}
                      onClick={() => handleQuantityClick(index)}
                      style={
                        quantity.selected
                          ? { background: "lightgrey" }
                          : { background: "none" }
                      }
                    >
                      {quantity.quantity}
                    </label>
                  ))}
                </div>
              </div>
              <div className="datesi">
                <h3>Pick your start date and time?</h3>
                <div>
                  <input
                    type="datetime-local"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col" style={{ padding: 10 }}>
          <div className="whit" style={{ background: "white", margin: "5px" }}>
            <br />
            <h2>Booking Summary</h2>
            <hr />
            {carpetSizes.map((carpet, index) => {
              if (carpet.selected) {
                return (
                  <div className="row" key={index}>
                    <div className="col">{carpet.size}</div>
                    <div className="col">{carpet.price}</div>
                  </div>
                );
              }
              return null;
            })}
            <br />
            <div className="row">
              <div className="col">Total Amount : </div>
              <div className="col">{calculateTotalAmount()} AED</div>
            </div>
            <div className="row">
              <div className="col">VAT (5%) : </div>
              <div className="col">{calculateVAT()} AED</div>
            </div>
            <div className="row">
              <div className="col">Net to pay : </div>
              <div className="col">{calculateNetCost()} AED</div>
            </div>
          </div>
          {!Editing && (
            <button className="btn btn-success bknow" onClick={showModal}>
              BOOK NOW
            </button>
          )}

          {Editing && (
            <div>
              <Button
                onClick={showModal}
                style={{ width: "50%", background: "green", color: "white" }}
              >
                UPDATE BOOKING
              </Button>
              <Button
                className=""
                onClick={showModal}
                style={{ width: "50%", background: "red", color: "white" }}
              >
                CANCEL BOOKING
              </Button>
            </div>
          )}
        </div>
      </div>
      <br />
      <br />

      <br />
      <br />
    </div>
  );
}
