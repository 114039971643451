import React, { useState, useEffect, useRef } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import './Blog.css';
import BlogPost from './BlogPost';

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(3);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const loaderRef = useRef(null);
  const { path, url } = useRouteMatch();

  useEffect(() => {
    fetch('/blogPosts.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setPosts(data);
        const uniqueCategories = [...new Set(data.map(post => post.category))];
        setCategories(uniqueCategories);
      })
      .catch(error => console.error('Error fetching the blog posts:', error));
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loading) {
        loadMorePosts();
      }
    });
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loading]);

  const loadMorePosts = () => {
    setLoading(true);
    setTimeout(() => {
      setVisiblePosts(prevVisiblePosts => prevVisiblePosts + 3);
      setLoading(false);
    }, 1000);
  };

  return (
    <div id="blog">
      <div className="container" align="center">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            BLOG <span>POSTS</span>
          </h1>
        </div>
        <div className="content">
          <div className="blog-main">
            {posts.slice(0, visiblePosts).map(post => (
              <div key={post.urls} className="blog-post" data-aos="fade-down">
                <h2>{post.title}</h2>
                <img src={post.image} alt={post.title} />
                <p>{post.description}</p>
                
                <Link to={`${url}/${post.urls}`} className="read-more">Read More</Link>
              </div>
            ))}
            {loading && <p>Loading...</p>}
            <div ref={loaderRef}></div>
          </div>
         
        </div>
      </div>
      <Switch>
        <Route exact path={path}>
          {/* Blog main view */}
        </Route>
        <Route path={`${path}/:urls`}>
          <BlogPost posts={posts} />
        </Route>
      </Switch>
    </div>
  );
}
