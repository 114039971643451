import React, { useEffect, useState } from "react";
import { Input, message, Modal, Button } from "antd";
import axios from "axios";
import StripeForm from "./Card/Card";
import useFetch from "../../../Hook/useFetch";
import { USER_API_URL } from "../../../Api/Api";
import { useHistory } from "react-router-dom";

export default function DryCleaning() {
  axios.defaults.withCredentials = true;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [clothSize, setClothSize] = useState([]);
  const { data: DryCleaning = [] } = useFetch(
    `${USER_API_URL}/drycleaning/one`
  );

  useEffect(() => {
    console.log("DryCleaning", DryCleaning);
    if (DryCleaning.length > 0) {
      const formattedClothSize = DryCleaning.map((item) => ({
        size: item.Name,
        selected: false,
        price: item.Price,
      }));
      setClothSize(formattedClothSize);
    }
  }, [DryCleaning]);

  const [startDate, setStartDate] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [visible, setVisible] = useState(false);

  // State for managing bookings
  const [bookingId, setBookingId] = useState("");
  const [bookingDetails, setBookingDetails] = useState(null);

  // Handlers for input changes
  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleContactChange = (e) => setContact(e.target.value);
  const handleBookingIdChange = (e) => setBookingId(e.target.value);

  const showModal = () => setVisible(true);
  const handleOk = () => {
    if (name && email && contact && startDate) {
      onBooking();
      setVisible(false);
    } else {
      message.warning("Please fill out all required fields.");
    }
  };
  const handleCancel = () => setVisible(false);

  const handleSizeClick = (index) => {
    const updatedSizes = clothSize.map((item, i) => ({
      ...item,
      selected: i === index ? !item.selected : item.selected,
    }));
    setClothSize(updatedSizes);
  };

  const calculateTotalAmount = () => {
    return clothSize.reduce(
      (total, item) => (item.selected ? total + item.price : total),
      0
    );
  };

  const calculateVAT = () => {
    const totalAmount = calculateTotalAmount();
    return (totalAmount * 0.05).toFixed(2); // VAT is 5%
  };

  const calculateNetCost = () => {
    const totalAmount = calculateTotalAmount();
    const vat = calculateVAT();
    return (totalAmount + parseFloat(vat)).toFixed(2);
  };

  const onBooking = () => {
    const bookingDetails = {
      Name: name,
      Email: email,
      Contact: contact,
      startDate,
      booking: clothSize.filter((item) => item.selected),
      totalAmount: calculateTotalAmount(),
      vatAmount: calculateVAT(),
      totalPrice: calculateNetCost(),
    };

    axios
      .post(`${USER_API_URL}/drycleaning`, bookingDetails)
      .then((response) => {
        // message.success("Booking Successful");
        const { _id } = response.data;
        message.success("Booking successful!");
        history.push(`/success/${_id}`);
        // console.log("Booking successful", response.data);
        resetForm();
      })
      .catch((error) => {
        message.error("Booking Failed");
        console.error("There was an error booking!", error);
      });
  };

  const retrieveBooking = async () => {
    try {
      const response = await axios.get(
        `${USER_API_URL}/drycleaning/${bookingId}`
      );
      const data = response.data;
      setBookingDetails(data);
      setName(data.Name);
      setEmail(data.Email);
      setContact(data.Contact);
      setStartDate(data.startDate);
      const updatedClothSize = clothSize.map((item) => ({
        ...item,
        selected: data.booking.some((booking) => booking.size === item.size),
      }));
      setClothSize(updatedClothSize);
      message.success("Booking retrieved successfully!");
    } catch (error) {
      message.error("Failed to retrieve booking!");
    }
  };

  const updateBooking = async () => {
    const updatedDetails = {
      Name: name,
      Email: email,
      Contact: contact,
      startDate,
      booking: clothSize.filter((item) => item.selected),
      totalAmount: calculateTotalAmount(),
      vatAmount: calculateVAT(),
      totalPrice: calculateNetCost(),
    };

    try {
      await axios.put(
        `${USER_API_URL}/drycleaning/${bookingId}`,
        updatedDetails
      );
      message.success("Booking updated successfully!");
    } catch (error) {
      message.error("Failed to update booking!");
    }
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setContact("");
    setStartDate("");
    setClothSize(clothSize.map((item) => ({ ...item, selected: false })));
  };

  return (
    <div className="container">
      <br />
      <Modal
        title="Booking"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Request Booking"}
        width={800}
      >
        <div className="row">
          <div className="col">
            <StripeForm />
          </div>
          <div className="col">
            <div>
              <p>Enter your name:</p>
              <Input
                value={name}
                onChange={handleNameChange}
                style={{ height: 40 }}
                placeholder="Your Name"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your email:</p>
              <Input
                value={email}
                onChange={handleEmailChange}
                style={{ height: 40 }}
                placeholder="Email Address"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your phone:</p>
              <Input
                value={contact}
                onChange={handleContactChange}
                style={{ height: 40 }}
                placeholder="Contact Number"
              />
            </div>
          </div>
        </div>
      </Modal>
      <br />
      <div align="center">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">Dry Cleaning</h1>
          <div className="manage-booking">
            <div>
              <Input
                value={bookingId}
                onChange={handleBookingIdChange}
                placeholder="Enter Booking ID"
                style={{ width: "300px", marginRight: "10px" }}
              />
              <Button
                onClick={retrieveBooking}
                type="primary"
                style={{ marginRight: "10px" }}
              >
                Retrieve Booking
              </Button>
              <Button onClick={updateBooking} type="primary">
                Update Booking
              </Button>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-lg-7 col-md-6 col-sm-12 colxs-12">
          <div className="whit">
            <div>
              <div className="often">
                <h3>Select Size?</h3>
                <div>
                  {clothSize.map((item, index) => (
                    <label
                      key={index}
                      onClick={() => handleSizeClick(index)}
                      style={
                        item.selected
                          ? { background: "lightgrey", width: "45%" }
                          : { background: "none", width: "45%" }
                      }
                    >
                      {item.size}
                    </label>
                  ))}
                </div>
              </div>
              <br />
              <div className="datesi">
                <h3>Pick your start date and time?</h3>
                <div>
                  <input
                    type="datetime-local"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col" style={{ padding: 10 }}>
          <div className="whit" style={{ background: "white", margin: "5px" }}>
            <br />
            <h2>Booking Summary</h2>
            <hr />
            {clothSize.map((item, index) => {
              if (item.selected) {
                return (
                  <div className="row" key={index}>
                    <div className="col">{item.size}</div>
                    <div className="col">{item.price} AED</div>
                  </div>
                );
              }
              return null;
            })}
            <br />
            <div className="row">
              <div className="col">Total Amount:</div>
              <div className="col">{calculateTotalAmount()} AED</div>
            </div>
            <div className="row">
              <div className="col">VAT (5%):</div>
              <div className="col">{calculateVAT()} AED</div>
            </div>
            <div className="row">
              <div className="col">Net to pay:</div>
              <div className="col">{calculateNetCost()} AED</div>
            </div>
          </div>
          <button className="btn btn-success bknow" onClick={showModal}>
            BOOK NOW
          </button>
        </div>
      </div>
      <br />

      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
