import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Input, message } from "antd";
import { USER_API_URL } from "../../../Api/Api";
import StripeForm from "./Card/Card";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import Card from "../../Card/Card";

export default function Deepcleaning() {
  const history = useHistory();
  axios.defaults.withCredentials = true;
  const [date, setDate] = useState(""); // Initialize date state
  const [index, setIndex] = useState(0);
  const [apartments, setApartments] = useState([]); // Initialize apartments state
  const [villas, setVillas] = useState([]); // Initialize villas state
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [isEdit, setIsEdit] = useState(false); // Initialize isEdit state
  const [bookingId, setBookingId] = useState(null); // Initialize bookingId state for updating booking
  const [datax, setDatax] = useState(); // Initialize bookingId state for updating booking
  const [url, setUrl] = useState(""); // Initialize bookingId state for updating booking
  const [sch, seSch] = useState(""); // Initialize bookingId state for updating booking

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const addNumberProperty = (data, updateArray) => {
    const updatedArray = updateArray.map((item) => {
      if (item.path === "Villa") {
        return item; // Ignore Villa entries
      }
      const match =
        data.Apartment && data.Apartment.some((a) => a.name === item.name);
      if (match) {
        return { ...item, number: 1 };
      }
      return item;
    });
    return updatedArray;
  };

  const addNumberPropertyVilla = (data, updateArray) => {
    const updatedArray = updateArray.map((item) => {
      if (item.path === "Apartment") {
        return item; // Ignore Apartment entries
      }
      const match = data.Villa && data.Villa.some((a) => a.name === item.name);

      if (match) {
        return { ...item, number: 1 };
      }
      return item;
    });

    return updatedArray;
  };

  const addData = async (_id) => {
    try {
      // Replace '<your_api_endpoint>' with your actual API endpoint
      const response = await fetch(`${USER_API_URL}/deepBook/${_id}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setName(data.Name);
      setEmail(data.Userid);
      setContact(data.Contact);
      // console.log("gsgg", data);
      setDatax(data);
      setDate(data.DateTime);
      setIsEdit(true);
      setApartments(addNumberProperty(data, apartments));
      setVillas(addNumberPropertyVilla(data, villas));
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error fetching data
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleContactChange = (e) => {
    setContact(e.target.value);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (isEdit) {
      onUpdateBooking();
    } else {
      onBooking();
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handlePriceChange = (index, type) => {
    let updatedList = type === "apartment" ? [...apartments] : [...villas];
    updatedList[index].number = updatedList[index].number === 0 ? 1 : 0;
    type === "apartment" ? setApartments(updatedList) : setVillas(updatedList);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const fetchData = async () => {
    try {
      // Replace '<your_api_endpoint>' with your actual API endpoint
      const response = await fetch(`${USER_API_URL}/deepcleaning`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("dataxsss", data);
      // Assuming data is an array of objects with 'name', 'path', 'price', and '_id' properties
      const apartmentData = data.filter((item) => item.path === "Apartment");
      const villaData = data.filter((item) => item.path === "Villa");
      setApartments(apartmentData);
      setVillas(villaData);
      //  setEmail

      console.log("fetching data:", villaData);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error fetching data
    }
  };

  const calculateTotalCost = () => {
    let total = 0;
    apartments.forEach((item) => {
      item.number > 0 ? (total += item.price) : (total += 0);
    });

    villas.forEach((item) => {
      // total += item.price;
      item.number > 0 ? (total += item.price) : (total += 0);
    });
    return total;
  };

  const calculateVAT = (totalCost) => {
    return (totalCost * 0.05).toFixed(2);
  };

  const calculateNetCost = (totalCost, vat) => {
    return (totalCost + parseFloat(vat)).toFixed(2);
  };

  const totalCost = calculateTotalCost(); // Calculate total cost
  const vat = calculateVAT(totalCost); // Calculate VAT based on total cost
  const netCost = calculateNetCost(totalCost, vat); // Calculate net cost

  const onBooking = async () => {
    const data = {
      Userid: email,
      Contact: contact,
      Name: name,
      DateTime: date,
      TotalPrice: totalCost,
      Vat: vat,
      NetCost: netCost,
      Villa: villas
        .filter((item) => item.number > 0)
        .map((item) => ({ _id: item._id, price: item.price, name: item.name })),
      Apartment: apartments
        .filter((item) => item.number > 0)
        .map((item) => ({ _id: item._id, price: item.price, name: item.name })),
    };
    try {
      const response = await axios.post(`${USER_API_URL}/deepBook`, data, {
        withCredentials: true, // Include credentials in the request
      });
      // console.log("Data Ex", response);
      const { _id } = response.data;
      message.success("Booking Successful");
      window.location.reload();
      // history.push(`/success/${_id}`);
    } catch (error) {
      message.error("Failed");
    }
  };

  const onUpdateBooking = async () => {
    const data = {
      // _id: bookingId,
      Userid: email,
      Contact: contact,
      Name: name,
      DateTime: date,
      TotalPrice: totalCost,
      Vat: vat,
      NetCost: netCost,
      Villa: villas
        .filter((item) => item.number > 0)
        .map((item) => ({ _id: item._id, price: item.price, name: item.name })),
      Apartment: apartments
        .filter((item) => item.number > 0)
        .map((item) => ({ _id: item._id, price: item.price, name: item.name })),
    };
    // console.log("Final Booking data:", data);
    try {
      const response = await axios.put(
        `${USER_API_URL}/deepBook/${sch}`,
        data,
        {
          withCredentials: true, // Include credentials in the request
        }
      );
      console.log("Response data:", response.data);
      // alert("Update Success");
      message.success("Booking Successfull");
      history.push(`/success/${_id}`);
      // Optionally reload or update state here
    } catch (error) {
      // alert("Update Failed");
      message.error("Booking Failed");
      console.error("Error:", error);
    }
  };

  const onCancelBooking = async () => {
    try {
      const response = await axios.delete(`${USER_API_URL}/deepBook/${email}`, {
        withCredentials: true, // Include credentials in the request
      });
      console.log("Response data:", response.data);
      alert("Cancel Success");
      // Optionally reload or update state here
    } catch (error) {
      alert("Cancel Failed");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when component mounts
    var a = window.location.href;
    let dataspd = a.split("?")[1];
    setUrl(dataspd);
    console.log("dataspd");
    // console.log("sfsfsfsf", a.split("?")[1]);
  }, []);

  return (
    <div className="container">
      <div>
        <Modal
          title="Booking"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={isEdit ? "Update Booking" : "Request Booking"}
          width={800}
        >
          <div className="row">
            <div className="col">
              {/* <Card /> */}
              <StripeForm />
            </div>
            <div className="col">
              <div>
                <p>Enter your name:</p>
                <Input
                  value={name}
                  onChange={handleNameChange}
                  style={{ height: 40 }}
                  placeholder="Your Name"
                />
              </div>
              <div style={{ marginTop: "8px" }}>
                <p>Enter your email:</p>
                <Input
                  value={email}
                  onChange={handleEmailChange}
                  style={{ height: 40 }}
                  placeholder="Email Address"
                />
              </div>
              <div style={{ marginTop: "8px" }}>
                <p>Enter your phone:</p>
                <Input
                  value={contact}
                  onChange={handleContactChange}
                  style={{ height: 40 }}
                  placeholder="Contact Number"
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <br />

      <br />
      <div align="center">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            Deep Cleaning
            <br />
          </h1>
          {/* {url == "manage" ? ( */}
          <div className="namagedata">
            <Input
              onChange={(e) => seSch(e.target.value)}
              placeholder="BOOKING REFERENCE"
              style={{ width: "300px", marginRight: "10px" }}
            />{" "}
            <Button type="primary" onClick={() => addData(sch)}>
              Manage Booking
            </Button>
          </div>
          {/* ) : (
            ""
          )} */}
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
          <div className="whit">
            <div>
              <div className="often">
                <h3>What do you want to deep clean?</h3>
                <div>
                  <label
                    onClick={() => setIndex(0)}
                    style={
                      index === 0
                        ? {
                            background: "#df2528",
                            width: "30%",
                            color: "white",
                          }
                        : { background: "none", width: "30%" }
                    }
                  >
                    Villas
                  </label>
                  <label
                    onClick={() => setIndex(1)}
                    style={
                      index === 1
                        ? { background: "#df2528", color: "white" }
                        : { background: "none" }
                    }
                  >
                    Apartments
                  </label>
                  <label style={{ width: "30%" }}>
                    <Link to="/Quote">Office / Warehouse</Link>
                  </label>
                </div>
              </div>
              <br />

              {index === 1 ? (
                <div className="often">
                  <div style={{ marginLeft: "20px", fontWeight: "bold" }}>
                    APARTMENTS
                  </div>
                  <br />
                  {apartments.map((item, index) => (
                    <label
                      key={index}
                      onClick={() => handlePriceChange(index, "apartment")}
                      style={
                        item.number === 1
                          ? { background: "#df2528", color: "white" }
                          : { background: "none" }
                      }
                    >
                      {item.name}
                    </label>
                  ))}
                </div>
              ) : index === 0 ? (
                <div className="often">
                  <div style={{ marginLeft: "20px", fontWeight: "bold" }}>
                    VILLAS
                  </div>
                  <br />
                  {villas.map((item, index) => (
                    <label
                      key={index}
                      onClick={() => handlePriceChange(index, "villa")}
                      style={
                        item.number === 1
                          ? { background: "#df2528", color: "white" }
                          : { background: "none" }
                      }
                    >
                      {item.name}
                    </label>
                  ))}
                </div>
              ) : (
                <div className=" "></div>
              )}

              <br />
              <div className="datesi">
                <h3>Pick your start date and time ?</h3>
                <div>
                  <input
                    style={{
                      borderRadius: 10,
                    }}
                    type="datetime-local"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col" style={{ padding: 10 }}>
          <div className="whit" style={{ background: "white", margin: "5px" }}>
            <br />

            <h2>Booking Summary</h2>
            <hr />
            <div style={{ background: "lightgrey", padding: 10 }}>
              Apartments
            </div>
            <div style={{ padding: 10 }}>
              {apartments.map((item, index) => (
                <div key={index}>
                  {item.number > 0 && (
                    <div className="row">
                      <div className="col">{item.name}</div>
                      <div className="col">{item.number * item.price} AED</div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div style={{ background: "lightgrey", padding: 10 }}>Villas</div>
            <div style={{ padding: 10 }}>
              {villas.map((item, index) => (
                <div key={index}>
                  {item.number > 0 && (
                    <div className="row">
                      <div className="col">{item.name}</div>
                      <div className="col">{item.number * item.price} AED</div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <hr />

            <div className="row">
              <div className="col">Date and Time:</div>{" "}
              <div className="col">{formatDate(date)}</div>
            </div>

            <br />
            <div className="row">
              <div className="col">Total Amount:</div>{" "}
              <div className="col">{totalCost} AED</div>
            </div>
            <br />
            <div className="row">
              <div className="col">VAT (5%):</div>{" "}
              <div className="col">{vat} AED</div>
            </div>
            <br />
            <div className="row">
              <div className="col">Net to pay:</div>{" "}
              <div className="col" style={{ fontSize: "23px" }}>
                {netCost} AED
              </div>
            </div>
          </div>
          <button
            className="btn btn-success bknow"
            onClick={showModal}
            style={
              isEdit ? { width: "40%", background: "green" } : { width: "100%" }
            }
          >
            {isEdit ? "UPDATE" : "BOOK NOW"}
          </button>
          {isEdit && (
            <button
              className="btn btn-danger"
              onClick={onCancelBooking}
              style={{ marginLeft: "10px", width: "48%" }}
            >
              CANCEL BOOKING
            </button>
          )}
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
