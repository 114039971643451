import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Styles.css";
import { Input, Modal, Button, message } from "antd";
import { USER_API_URL } from "../../../Api/Api";
import StripeForm from "./Card/Card";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Sofacleaning() {
  axios.defaults.withCredentials = true;
  const history = useHistory();
  const [date, setDate] = useState("");
  const [cleaner1, setCleaner1] = useState(0);
  const [cleaner2, setCleaner2] = useState(0);
  const [cleaner3, setCleaner3] = useState(0);
  const [cleaner4, setCleaner4] = useState(0);
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const [url, setUrl] = useState(""); // Initialize url state
  const [sch, seSch] = useState(""); // Initialize sch state

  useEffect(() => {
    window.scrollTo(0, 0);
    var a = window.location.href;
    let dataspd = a.split("?")[1];
    setUrl(dataspd);
    console.log("dataspd");
  }, []);

  const seaters = [
    {
      name: "Two (2) Seater",
      price: 120,
      quantity: cleaner1,
      setQuantity: setCleaner1,
    },
    {
      name: "Three (3) Seater",
      price: 175,
      quantity: cleaner2,
      setQuantity: setCleaner2,
    },
    {
      name: "Four (4) Seater",
      price: 225,
      quantity: cleaner3,
      setQuantity: setCleaner3,
    },
    {
      name: "Five (5) Seater",
      price: 250,
      quantity: cleaner4,
      setQuantity: setCleaner4,
    },
  ];

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleContactChange = (e) => {
    setContact(e.target.value);
  };

  const onBooking = async () => {
    const totalPrice = seaters.reduce(
      (acc, curr) => acc + curr.price * curr.quantity,
      0
    );
    const vatAmount = (totalPrice * 5) / 100;
    const totalAmount = totalPrice + vatAmount;

    const data = {
      Two_Seater: cleaner1,
      Three_Seater: cleaner2,
      Four_Seater: cleaner3,
      Five_Seater: cleaner4,
      datetime: date,
      totalPrice: totalPrice,
      vatAmount: vatAmount,
      totalAmount: totalAmount,
      Email: email,
      Contact: contact,
      Name: name,
      DateTime: date,
    };

    try {
      const response = await axios.post(
        `${USER_API_URL}/seaatercleaning`,
        data,
        {
          withCredentials: true,
        }
      );
      // console.log("Booking data:", data);
      const { _id } = response.data;
      message.success("Booked Successfully");
      // message.success("Booking Successful");
      history.push(`/success/${_id}`);
    } catch (error) {
      message.error("Booking Failed");
      // console.error("Error:", error);
    }
  };

  const addData = async (_id) => {
    try {
      const response = await fetch(`${USER_API_URL}/seaatercleaning/${_id}`);
      message.success("Booking successful!");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("fetched data:", data);
      setDate(data.datetime);
      setName(data.Name);
      setEmail(data.Email);
      setContact(data.Contact);
      setCleaner1(data.Two_Seater);
      setCleaner2(data.Three_Seater);
      setCleaner3(data.Four_Seater);
      setCleaner4(data.Five_Seater);
      setIsEdit(true);
      setBookingId(_id);
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Booking successful!");
    }
  };

  const onUpdateBooking = async () => {
    const totalPrice = seaters.reduce(
      (acc, curr) => acc + curr.price * curr.quantity,
      0
    );
    const vatAmount = (totalPrice * 5) / 100;
    const totalAmount = totalPrice + vatAmount;

    const data = {
      Two_Seater: cleaner1,
      Three_Seater: cleaner2,
      Four_Seater: cleaner3,
      Five_Seater: cleaner4,
      datetime: date,
      totalPrice: totalPrice,
      vatAmount: vatAmount,
      totalAmount: totalAmount,
      Email: email,
      Contact: contact,
      Name: name,
    };

    try {
      const response = await axios.put(
        `${USER_API_URL}/seaatercleaning/${bookingId}`,
        data,
        {
          withCredentials: true,
        }
      );
      // console.log("Response data:", response.data);
      message.success("Update Success");
    } catch (error) {
      message.error("Update Failed");
      // console.error("Error:", error);
    }
  };

  const onCancelBooking = async () => {
    try {
      const response = await axios.delete(
        `${USER_API_URL}/seaatercleaning/${email}`,
        {
          withCredentials: true,
        }
      );
      console.log("Response data:", response.data);
      message.success("Cancel Success");
    } catch (error) {
      message.error("Cancel Failed");
      console.error("Error:", error);
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (isEdit) {
      onUpdateBooking();
    } else {
      onBooking();
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const strg = (datexx) => {
    if (datexx == 0) {
      return "";
    }
    const date = new Date(datexx);
    const formattedDate = date.toLocaleString();
    return formattedDate;
  };

  return (
    <div className="container">
      <br />
      <Modal
        title="Booking"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={isEdit ? "Update Booking" : "Request Booking"}
        width={800}
      >
        <div className="row">
          <div className="col">
            <StripeForm />
          </div>

          <div className="col">
            <div>
              <p>Enter your name:</p>
              <Input
                value={name}
                onChange={handleNameChange}
                style={{ height: 40 }}
                placeholder="Your Name"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your email:</p>
              <Input
                value={email}
                onChange={handleEmailChange}
                style={{ height: 40 }}
                placeholder="Email Address"
              />
            </div>
            <div style={{ marginTop: "8px" }}>
              <p>Enter your phone:</p>
              <Input
                value={contact}
                onChange={handleContactChange}
                style={{ height: 40 }}
                placeholder="Contact Number"
              />
            </div>
          </div>
        </div>
      </Modal>
      <br />
      <div align="center">
        <div className="heading" align="center">
          <h1 data-aos="fade-down">
            Sofa Cleaning
            <br />
          </h1>

          <div>
            <Input
              // value={searchId}
              // onChange={handleSearchIdChange}
              onChange={(e) => seSch(e.target.value)}
              placeholder="Enter Booking ID"
              style={{ width: "300px", marginRight: "10px" }}
            />
            <Button
              // onClick={() => fetchBookingData(searchId)}
              onClick={() => addData(sch)}
              type="primary"
              style={{ marginRight: "10px" }}
            >
              Retrieve Booking
            </Button>
            <Button
              onClick={() => {
                window.location.reload();
              }}
              type="default"
            >
              Reset
            </Button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-lg-7 col-md-6 col-sm-12 colxs-12 ">
          <div className="whit">
            <div>
              {seaters.map((seater, index) => (
                <div key={index}>
                  <div className="increment">
                    <h3>{seater.name}</h3>
                    <div className="tabc">
                      <label
                        onClick={() => seater.setQuantity(seater.quantity - 1)}
                      >
                        -
                      </label>
                      <span>{seater.quantity}</span>
                      <label
                        onClick={() => seater.setQuantity(seater.quantity + 1)}
                      >
                        +
                      </label>
                    </div>
                  </div>
                  <br />
                </div>
              ))}

              <div className="datesi">
                <h3>Pick your start date and time ?</h3>
                <div>
                  <input
                    style={{ borderRadius: 10 }}
                    type="datetime-local"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className="datebox"
                  />
                </div>
              </div>
              <div className="cent"></div>
              <br />
              <br />
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-6 col-sm-12 colxs-12 ">
          <div className="whit" style={{ background: "white", margin: "5px" }}>
            <h2>Booking Summary</h2>
            <hr />
            <br />
            <div className="row">
              <div className="col" style={{ fontWeight: "bold" }}>
                Two Seater:{" "}
              </div>{" "}
              <div className="col">{cleaner1 * 120}</div>
            </div>
            <div className="row">
              <div className="col" style={{ fontWeight: "bold" }}>
                Three Seater:{" "}
              </div>{" "}
              <div className="col">{cleaner2 * 175}</div>
            </div>
            <div className="row">
              <div className="col" style={{ fontWeight: "bold" }}>
                Four Seater:{" "}
              </div>{" "}
              <div className="col">{cleaner3 * 225}</div>
            </div>
            <div className="row">
              <div className="col" style={{ fontWeight: "bold" }}>
                Five Seater:{" "}
              </div>{" "}
              <div className="col">{cleaner4 * 250}</div>
            </div>
            <hr />
            <br />
            <div className="row">
              <div className="col">Total Amount : </div>{" "}
              <div className="col">
                {cleaner1 * 120 +
                  cleaner2 * 175 +
                  cleaner3 * 225 +
                  cleaner4 * 250}
                .0 AED
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">Vat : </div>{" "}
              <div className="col">
                {((cleaner1 * 120 +
                  cleaner2 * 175 +
                  cleaner3 * 225 +
                  cleaner4 * 250) *
                  5) /
                  100}
                .0 AED
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">Net to pay : </div>{" "}
              <div className="col">
                {cleaner1 * 120 +
                  cleaner2 * 175 +
                  cleaner3 * 225 +
                  cleaner4 * 250 +
                  ((cleaner1 * 120 +
                    cleaner2 * 175 +
                    cleaner3 * 225 +
                    cleaner4 * 250) *
                    5) /
                    100}
                .0 AED
              </div>
            </div>
          </div>
          <button
            className="btn btn-success bknow"
            onClick={showModal}
            style={
              isEdit ? { width: "40%", background: "green" } : { width: "100%" }
            }
          >
            {isEdit ? "UPDATE" : "BOOK NOW"}
          </button>
          {isEdit && (
            <button
              className="btn btn-danger"
              onClick={onCancelBooking}
              style={{ marginLeft: "10px", width: "48%" }}
            >
              CANCEL BOOKING
            </button>
          )}
        </div>
      </div>

      <br />
      <br />
      <br />
    </div>
  );
}
